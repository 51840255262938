import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BesidesWorkPage = () => (
  <Layout>
    <SEO title="Besides Work" />
    <h1>Besides Work</h1>
    <p>Coming soon</p>
  </Layout>
)

export default BesidesWorkPage